
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;1,300&family=Poppins:wght@100&display=swap');

#videoBox{

}
.policycondition{
    padding: 8px;
    font-weight: 400;
    font-size: 12px;
}
.modalbuttons{
    align-items: center;
    justify-content: end;
    display: flex;
}
.videomob{
    display: none;
}
#inputBox{
    /* height: 8%; */
    display: flex;
    box-shadow: rgb(158 158 158) 2px 4px 4px;
    /* margin-top: 18px; */
    align-items: center;
    border-radius: 100px;
    justify-content: space-between;
    padding: 15px;
    margin-top: 10px;
}
.dialog-container .MuiDialog-container > div{
    background: transparent !important;
}
.dialog-container{
    /* opacity: 1; */
}
#mainContainer{
    height: 100vh;
    width: 35%;
    padding-bottom: 10px;
}
.modal {
    z-index: 10000;
}
#mainContainerModal{
    height: 100%;
    /* z-index : 1000;     */
    /* position: absolute; */
    /* right: 75px; */
    width: 100%;
    /* transition: width 1s ease-in */
}
.infoMic{
    bottom: 13px;
    left: 12px;
}
.left-text1{
    
    font-size: 12px;
    color: black;
    font-weight: 500;
    margin: 0px  0px 0px 13px;
}
.left-text2{
    font-size: 13px;
    color: #429efa;
    padding-left: 10px;
}
.left-text1 svg{
    color: #429efa;
    width: 20px;
    height: 20px;
}
.left-main-container{
    height: 100%;
    display: flex;
    /* min-width: 100%; */
    align-items: baseline;
    /* padding-right: 10px; */
    flex-direction: column;

}
.left-btns-container
{
    display: flex;     
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    height: 100%;
}
#videoBox {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: url("../videos/background.png");
    background-repeat: no-repeat;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
}
#videoBox1 {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: url("../videos/animation_bg.gif");
    background-repeat: no-repeat;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
}
#micButtonMobile{
    display: none;
}
.modal-content{
    border-radius: 16px;
}
#headerLabel{
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}
#desc{
    font-family: 'Open Sans', sans-serif;
}
@media screen and (max-width: 802px) 
{
    #videoBox{
        object-fit: cover;
    }
}
@media screen and (max-width: 690px) {


    #modalContainer{
        /* width: 90%; */
    }

    #modalContainer #headerLabel{
        font-size: 3vh
    }

    #modalContainer #smallHeaderLabel{
        font-weight: 600;
        color: rgb(42, 42, 42);
        width: 100%;
        margin: 14px 0px 15px;
        font-size: 13px;    
    }

    #modalContainer #desc{
        margin-left: 2%;
        color: rgb(66, 158, 250);
        font-size: 13px;
        line-height: 1.5;
        font-weight: 500;
    }

    #modalContainer #checkedButtonContainer{
        /* margin: 12px 22px 12px 22px; */
    }

    #modalContainer #modalbtn{
        font-weight: 600;
        border: none;
        color: white;
        cursor: pointer;
        margin: 5px 0;
        padding: 6px 15px 6px 15px;
        border-radius: 20px;
    }

    #modalContainer #checkbox{
        width: 2.5vh;
        height: 2.5vh;
    }

  }

@media screen and (max-width: 960px) {
    .left-text1{
        display: none;
    }
}
@media screen and (max-width: 950px) {
    #mainContainer{
        width: 70%
    }
}
@media screen and (max-width: 700px) {
    #mainContainer{
        width: 100%
    }
}
@media screen and (max-width: 849px) {
    #chatbotMsgLabel{
        font-size : 14px;
        padding: 2vh;
        max-width: 100%;
        line-height: 1.2;
        border-top-right-radius: 5vh;
        border-bottom-left-radius: 4vh;
        border-bottom-right-radius: 5vh;
        margin: 0.5vh;  
    }
    #optionBtns{
        font-size: 12px;
        padding: 1.1vw 2vw;
    }

}

@media screen and (max-width: 600px) {

  /* .videoweb{
      display: none;
  }
  .videomob{
      display: block;
  } */
  #chatbotTimeLabel{
      color: #fff;
  }
  #clientTimeLabel{
      color: #fff;
  }
  #mainContainer{
      display: none;
  }
   .dialog-container{
       display: block;
   }
    #micButtonMobile{
        display:flex;
        margin-bottom: 28px;
    }
    #micButtonContainer{
        display:none;
    }
    .videoblur{
        /* filter: blur(8px); */
       
    }
    #inputBox{
        width: 100%;
        height: auto;
        margin: 0px 12px;
        padding: 0
    }
    .center-container{
        display: none;
    }
    #listContainer{
        padding-left: 12px;
        padding-right: 12px;
    }
    #chatbotMsgLabel{
        box-shadow: none;
    }
    #clientMsgLabel{
        box-shadow: none;
    }
    #chatImgButtonContainer{
        box-shadow: none;
    }
    #inputBox{
        box-shadow: none;
    }
    #blurContainer{
        background: linear-gradient(to bottom, transparent, #ffff);
        filter: blur(8px);
    }
    #sendButtonn{
        width: 35px;
        cursor: pointer;
        height: 35px;
        margin: 10px
    }
    .left-btns-container{
        align-items: center;
    }
 
}

/* ---------------------Company Logo--------------------- */

.logo-container {
    margin-top: 10px;
    /* position: absolute; */
    /* float: left; */
    width: 60px;
    height: 60px;
    cursor: pointer;
    background: #fff;
    overflow: hidden;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
    margin-left: 10px;
}

.logo-container span{
	font-size: 20px;
	font-weight: 500;
	line-height: 60px;
	margin-left: 10px;
}

.logo-container:hover {
	width: 200px;
}

.logo-container .icon {
    width: 60px;
    height: 60px;
    text-align: center;
    border-radius: 50px;
    display: inline-block;
    transition: all 0.3s ease-in-out;
}

.logo-container .icon img {
	height: 100%;
    transition: all 0.3s ease-in-out;
    width: 100%;
    /* align-content: center;
    text-align: center; */
}

.logo-container:hover img {
	color: #fff;
}
.para{
    display: content;
}
.para1{
    display: content;
}
.modal-backdrop{
    backdrop-filter: blur(2px) !important;
    background-color: transparent !important;

}
.contentsContainer-0-2-76::-webkit-scrollbar-thumb{
    background: rgb(66 158 250) !important;
}